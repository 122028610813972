.site-header.grid-container.fluid {
  position: absolute;
  background: none;
  border: none; }
  .site-header.grid-container.fluid .menu-section .burger-row .hamburger .hamburger-inner {
    background: #fff; }
    .site-header.grid-container.fluid .menu-section .burger-row .hamburger .hamburger-inner:before, .site-header.grid-container.fluid .menu-section .burger-row .hamburger .hamburger-inner:after {
      background: #fff; }
  .site-header.grid-container.fluid .menu-section .burger-row .hamburger.is-active .hamburger-inner {
    background: #3d7cc9; }
    .site-header.grid-container.fluid .menu-section .burger-row .hamburger.is-active .hamburger-inner:before, .site-header.grid-container.fluid .menu-section .burger-row .hamburger.is-active .hamburger-inner:after {
      background: #3d7cc9; }

.home.section-one.grid-container.fluid {
  padding: 0;
  overflow: hidden; }
  .home.section-one.grid-container.fluid .grid-x {
    min-height: 100vh;
    position: relative; }
    .home.section-one.grid-container.fluid .grid-x img, .home.section-one.grid-container.fluid .grid-x video {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 0;
      -o-object-fit: cover;
      object-fit: cover;
      max-width: 100%; }
    .home.section-one.grid-container.fluid .grid-x .mask {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.25);
      z-index: 1; }
    .home.section-one.grid-container.fluid .grid-x .txt {
      position: relative;
      z-index: 2;
      text-align: center; }
      .home.section-one.grid-container.fluid .grid-x .txt p {
        font-size: 2.6rem;
        line-height: 3.6rem; }
